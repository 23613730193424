// import store from '@/state/store'

export default [{
    path: '/',
    name: '/',
    // redirect:'/index/apply',
    component: () => import('../views/pages/index'),
    // meta: {
    //     beforeResolve(routeTo, routeFrom, next) {
    //         // If the user is already logged in
    //         if (store.getters['auth/loggedIn']) {
    //             // Redirect to the home page instead
    //             next({ name: 'home' })
    //         } else {
    //             // Continue to the login page
    //             next()
    //         }
    //     },
    // },
  },
  // {
  //   path: '/register',
  //   name: 'register',
  //   component: () => import('../views/pages/account/register'),
  //   // meta: {
  //   // },
  // },
  // {
  //   path: '/forgot-password',
  //   name: 'Forgot-password',
  //   component: () => import('../views/pages/account/forgot-password'),
  //   // meta: {
  //   // },
  // },
  // {
  //   path: '/forgot-password2',
  //   name: 'Forgot-password2',
  //   component: () => import('../views/pages/account/forgot-password2'),
  //   // meta: {
  //   // },
  // },
  // {
  //   path: '/findPassword',
  //   name: 'Find-Password',
  //   component: () => import('../views/pages/account/find-password'),
  // },
  // {
  //   path: '/weekPassword',
  //   name: 'WeekPassword',
  //   component: () => import('../views/pages/account/week-password'),
  // },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/pages/account/login'),
    // meta: {
    //     beforeResolve(routeTo, routeFrom, next) {
    //         if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
    //             store.dispatch('auth/logOut')
    //         } else {
    //             store.dispatch('authfack/logout')
    //         }
    //         const authRequiredOnPreviousRoute = routeFrom.matched.some(
    //             (route) => route.push('/login')
    //         )
    //         // Navigate back to previous page, or home as a fallback
    //         next(authRequiredOnPreviousRoute ? { name: 'home' } : { ...routeFrom })
    //     },
    // },
  },
  /*
   浏览器要求
   */
  {
    path: '/browser',
    name: 'browser',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/account/browser')
  },
  {
    path: '/home',
    name: 'home',
    meta: {
        authRequired: true,
    },
    component: () => import('../views/pages/examinee/index')
  },
  {
    path: '/casLogin',
    name: 'casLogin',
    component: () => import('../views/pages/casLogin')
  },
  // 更多推送消息
  {
    path: '/tipsList',
    name: 'tipsList',
    meta: {
        authRequired: true,
    },
    component: () => import('../views/pages/examinee/tipsList')
  },
  // 更多系统消息
  {
    path: '/list',
    name: 'list',
    meta: {
        authRequired: true,
    },
    component: () => import('../views/pages/examinee/list')
  },
  // 详情
  {
    path: '/details',
    name: 'details',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/examinee/details')
  },
  // 考试详情
  {
    path: '/announcementDetails',
    name: 'announcementDetails',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/examinee/announcementDetails')
  },
  {
    path: '/record',
    name: 'reg-record',
    meta: {
        authRequired: true,
    },
    component: () => import('../views/pages/examinee/record')
  },
  {
    path: '/recordList',
    name: 'recordList',
    meta: {
        authRequired: true,
    },
    component: () => import('../views/pages/examinee/record-list')
  },
  {
    path: '/specialList',
    name: 'specialList',
    meta: {
        authRequired: true,
    },
    component: () => import('../views/pages/examinee/special-list ')
  },
  {
    path: '/agreement',
    name: 'agreement',
    meta: {
        authRequired: true,
    },
    component: () => import('../views/pages/examinee/agreement')
  },
  {
    path: '/commitment',
    name: 'commitment',
    meta: {
        authRequired: true,
    },
    component: () => import('../views/pages/examinee/commitment')
  },
  {
    path: '/ticketPrinting',
    name: 'ticketPrinting',
    meta: {
        authRequired: true,
    },
    component: () => import('../views/pages/examinee/ticket-printing')
  },
  {
    path: '/scoreQuery',
    name: 'scoreQuery',
    meta: {
        authRequired: true,
    },
    component: () => import('../views/pages/examinee/score-query')
  },
  {
    path: '/recheckScore',
    name: 'recheckScore',
    meta: {
        authRequired: true,
    },
    component: () => import('../views/pages/examinee/score-recheckList')
  },
  // 个人信息完善1
  {
    path: '/personalData',
    name: 'personalData',
    meta: {
        authRequired: true,
    },
    component: () => import('../views/pages/examinee/personal-data')
  },
  // 个人信息完善2
  {
    path: '/personalImage',
    name: 'personalImage',
    meta: {
        authRequired: true,
    },
    component: () => import('../views/pages/examinee/personal-image')
  },
  // 个人信息完善3
  {
    path: '/personalEducation',
    name: 'personalEducation',
    meta: {
        authRequired: true,
    },
    component: () => import('../views/pages/examinee/personal-education')
  },
  // 个人信息完善4
  {
    path: '/personalWork',
    name: 'personalWork',
    meta: {
        authRequired: true,
    },
    component: () => import('../views/pages/examinee/personal-work')
  },
  // 个人信息完善5
  {
    path: '/personalAccount',
    name: 'personalAccount',
    meta: {
        authRequired: true,
    },
    component: () => import('../views/pages/examinee/personal-account')
  },
  // 报名信息提交
  {
    path: '/enrollForm',
    name: 'enrollForm',
    meta: {
        authRequired: true,
    },
    component: () => import('../views/pages/examinee/enroll-form')
  },
  // 报名缴费
  {
    path: '/enrollFormPay',
    name: 'enrollFormPay',
    meta: {
        authRequired: true,
    },
    component: () => import('../views/pages/examinee/enroll-form-pay')
  },
  // 报名详情
  {
    path: '/applyInfo',
    name: 'applyInfo',
    meta: {
        authRequired: true,
    },
    component: () => import('../views/pages/examinee/applyInfo')
  },
  // 成绩查询列表
  {
    path: '/scoreList',
    name: 'scoreList',
    meta: {
        authRequired: true,
    },
    component: () => import('../views/pages/examinee/score-list')
  },
  // 成绩复核提交
  {
    path: '/scoreCheck',
    name: 'scoreCheck',
    meta: {
        authRequired: true,
    },
    component: () => import('../views/pages/examinee/score-check')
  },
  // 证书邮寄
  {
    path: '/scoreSend',
    name: 'scoreSend',
    meta: {
        authRequired: true,
    },
    component: () => import('../views/pages/examinee/score-send')
  },
  // 成绩复核
  {
    path: '/recordReview',
    name: 'recordReview',
    meta: {
        authRequired: true,
    },
    component: () => import('../views/pages/examinee/record-review')
  },
  // 成绩查询条件
  {
    path: '/scoreCondition',
    name: 'scoreCondition',
    meta: {
        authRequired: true,
    },
    component: () => import('../views/pages/examinee/score-condition')
  },
  // 证书邮寄
  {
    path: '/recordCertificate',
    name: 'recordCertificate',
    meta: {
        authRequired: true,
    },
    component: () => import('../views/pages/examinee/record-certificate')
  },
  // 证书列表
  {
    path: '/certificate',
    name: 'certificate',
    meta: {
        authRequired: true,
    },
    component: () => import('../views/pages/examinee/certificate')
  },

  // 考试历史记录查询
  {
    path: '/enrollHistory',
    name: 'enrollHistory',
    meta: {
        authRequired: true,
    },
    component: () => import('../views/pages/examinee/enroll-history')
  },
  // 考试历史记录查询
  {
    path: '/examIndex',
    name: 'examIndex',
    meta: {
        authRequired: true,
    },
    component: () => import('../views/pages/examinee/examType/examIndex')
  },
  // 首页
  {
    path: '/index',
    name: 'index',
    redirect: '/index/apply',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/index'),
    children: [

      {
        // 当 /index/apply 匹配成功
        // UserProfile 将被渲染到 User 的 <router-view> 内部
        path: 'apply',
        component: () => import('../views/pages/index/apply'),
      },
    ],
  },
  {
    path: '/ticketList',
    name: 'ticketList',
    component: () => import('../views/pages/index/ticketList'),
  },
  // 准考证打印查询
  {
    path: '/ticketSearch',
    name: 'ticketSearch',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/index/ticketSearch')
  },
  // 详情
  {
    path: '/newsDetails',
    name: 'newsDetails',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/index/newsDetails')
  },
  //  成绩打印查询
  {
    path: '/index/scoreList',
    name: 'indexScoreList',
    component: () => import('../views/pages/index/scoreList'),
  }, //  成绩打印查询
  {
    path: '/index/scoreSearch',
    name: 'scoreSearch',
    component: () => import('../views/pages/index/scoreSearch'),
  },
  // 新闻列表
  {
    path: '/newsList',
    name: 'newsList',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/index/newsList')
  },
  // 考试列表
  {
    path: '/examList',
    name: 'examList',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/index/examList')
  },
  // 考试分类·
  {
    path: '/examTypeList',
    name: 'examTypeList',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/index/examTypeList')
  },

  // 考试计划·
  {
    path: '/examPlan',
    name: 'examPlan',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/index/examPlan')
  },
  // 常见问题·
  {
    path: '/questionList',
    name: 'questionList',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/index/questionList')
  },
  // 介绍
  {
    path: '/intro',
    name: 'intro',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/index/Intro')
  },
  // common详情
  {
    path: '/common',
    name: 'common',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/index/common')
  },
  // 专题·
  {
    path: '/special',
    name: 'special',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/index/special')
  },
  // 打印报名表
  {
    path: '/applyPrint',
    name: 'applyPrint',
    meta: {
        authRequired: true,
    },
    component: () => import('../views/pages/examinee/print/applyPrint')
  },
  // 打印成绩表
  {
    path: '/gradePrint',
    name: 'gradePrint',
    meta: {
        authRequired: true,
    },
    component: () => import('../views/pages/examinee/print/gradePrint')
  },
  // 打印准考证
  {
    path: '/printCard',
    name: 'printCard',
    meta: {
        authRequired: true,
    },
    component: () => import('../views/pages/examinee/print/printCard')
  },
  // 实际准考证
  {
    path: '/actualTicket',
    name: 'actualTicket',
    // meta: {
    //   authRequired: true,
    // },
    component: () => import('../views/pages/examinee/print/actualTicket')
  },
  //404
  {
    path: '/pages/error-404',
    name: 'Error-404',
    // meta: {
    //   authRequired: true
    // },
    component: () => import('../views/pages/utility/error-404')
  },
  {
    path: '/pages/error-500',
    name: 'Error-500',
    // meta: {
    //   authRequired: true
    // },
    component: () => import('../views/pages/utility/error-500')
  },

  {
    path: '*',
    redirect: {
      name: "Error-404"
    }
  },

]
